
import {
    IonPage,
    IonContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/vue';
import {defineComponent} from 'vue'
import {removeUserFromStorage, removeUserRatingFromStorage} from "@/util/LocalStateService";

export default defineComponent({
    name: 'Error',
    components: {
        IonPage,
        IonContent,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
    },
    props: {
        error: {
            type: String,
        },
    },
    methods: {
        async reload() {
            await removeUserFromStorage()
            removeUserRatingFromStorage().then(()=>{
                window.location.href = window.location.origin;
            })
        }
    }
})
